;(function (w, d) {

  function deviceListeners() {
    const devices = d.getElementsByClassName('hoverDevice');

    for (let i = 0; i < devices.length; i++) {
      const device = devices[i];
      const vid = device.querySelector('.hoverVideo');
      device.addEventListener('mouseenter', function (e) {
        vid.style.opacity = 1;
        vid.play();
      });
      device.addEventListener('mouseleave', function (e) {
        vid.style.opacity = 0;
        vid.pause();
        vid.pause();
        vid.currentTime = 0;
      });
    }

  }

  function initTextScrambler() {

    const scrambleList = d.getElementsByClassName('scrambleText')

    for (let i = 0; i < scrambleList.length; i++) {

      const element = scrambleList[i],
        text = element.dataset.scrambleTo
      ;

      gsap.to(element, 3, {scrambleText: {text: text, chars: "1234", speed: 0.5}, scrollTrigger: '#sm__caseNumbers'});
    }

  }

  function heroGraphAnimation() {
    // animate #graph svg paths in from the bottom of the screen using GSAP
    const caseNumbers = d.querySelectorAll('.caseNumbers .numberBox'),
      graphGroups = d.querySelectorAll('#graph g'),
      infoGroups = d.querySelectorAll('.infoBox_container .infoBox');

    const graphTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.pageWrapper',
        start: 'left bottom',
        once: true,
      },
    });

    graphTl.to(
      '.st0',
      {
        autoAlpha: 1,
        duration: .5,
        y: 0,
        stagger: {
          amount: 1.5
        }
      },
    )
    graphTl.to(
      '.st2',
      {
        autoAlpha: 1,
        duration: .5,
        y: 0,
        stagger: {
          amount: 1.5
        }
      },
      '<'
    )
    graphTl.to(
      '.st1',
      {
        autoAlpha: 1,
        duration: .5,
        y: 0,
        stagger: {
          amount: 1.5
        }
      },
      '<'
    )

    for (let click = 0; click < caseNumbers.length; click++) {
      caseNumbers[click].addEventListener('mousedown', function (e) {
        e.preventDefault();

        // add active class to the case number you clicked and an inactive class to the others, so you can style them differently
        for (let graph = 0; graph < graphGroups.length; graph++) {
          graphGroups[graph].classList.remove('active');
          graphGroups[graph].classList.add('inactive');
        }
        for (let info = 0; info < infoGroups.length; info++) {
          infoGroups[info].classList.remove('visible');
          infoGroups[info].classList.add('hidden');
        }
        const graphGroup = d.querySelector('#graph g[id="' + this.getAttribute('data-graph-group') + '"]');
        const infoGroup = d.querySelector('.infoBox_container .infoBox[id="' + this.getAttribute('data-graph-group') + '"]');
        graphGroup.classList.add('active');
        graphGroup.classList.remove('inactive');
        infoGroup.classList.add('visible');
        infoGroup.classList.remove('hidden');

      });
    }

    // when you click outside the .numberBox or .infoBox remove the active and inactive class from the graph group and the visible class from the infoBox
    w.addEventListener('mousedown', function (e) {
      if (!e.target.closest('.numberBox, .infoBox')) {
        for (let graph = 0; graph < graphGroups.length; graph++) {
          graphGroups[graph].classList.remove('active');
          graphGroups[graph].classList.remove('inactive');
        }
        for (let info = 0; info < infoGroups.length; info++) {
          infoGroups[info].classList.remove('visible');
          infoGroups[info].classList.remove('hidden');
        }
      }
      if (e.target.closest('.closeBtn')) {
        for (let graph = 0; graph < graphGroups.length; graph++) {
          graphGroups[graph].classList.remove('active');
          graphGroups[graph].classList.remove('inactive');
        }
        for (let info = 0; info < infoGroups.length; info++) {
          infoGroups[info].classList.remove('visible');
          infoGroups[info].classList.remove('hidden');
        }
      }
    });

  }

  w.addEventListener('DOMContentLoaded', (e) => {

    if (d.getElementById('cloudBg')) {
      VANTA.FOG({
        el: "#cloudBg",
        mouseControls: true,
        touchControls: true,
        minHeight: 200.00,
        minWidth: 200.00,
        highlightColor: 0xf5f5f5,
        midtoneColor: 0xe8e8e8,
        lowlightColor: 0xb9b9b9,
        baseColor: 0xafafaf,
        blurFactor: 0.57,
        speed: 0.88,
        zoom: 1.30
      })
    }

    deviceListeners();

  });

  let swiperList = {}

  function initSwiper() {
    swiperList.smartsCarousel = new Swiper('.smartsSlider', {
      slidesPerView: 1,
      speed: 1200,
      autoplay: false,
      effect: 'fade',
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.smarts-pagination',
        type: "bullets",
        clickable: true,
        renderBullet: function (index, className) {
          let number = "";
          let description = "";
          let bgClass = "";
          switch (index) {
            case 0 :
              number = "01";
              description = "240+ tracked keywords";
              bgClass = "circleDarkGray";
              break;
            case 1 :
              number = "02";
              description = "300+ monthly calls";
              bgClass = "circleLightGray";
              break;
            case 2 :
              number = "03";
              description = "500% increase in revenue";
              bgClass = "circleLightBlue";
              break;
            case 3 :
              number = "04";
              description = "2,252% surge in monthly users";
              bgClass = "circleOrange";
              break;
          }
          return `<div class="col-lg-3 col-md-6 ${className}">
                              <span class="circleBg ${bgClass}">${number}</span>
                              <div class="mt-5">
                                <span>${description}</span>
                              </div>
                          </div>`;
        }
      },
      on: {
        slideChange: function () {
          if (this.el.classList.contains('smartsSlider') === true) {
            const currentSlide = this.slides[this.activeIndex]
            const slideTitle = currentSlide.firstElementChild;

            gsap.to(
              slideTitle,
              {
                duration: 1,
                scrambleText: {
                  text: "{original}",
                  chars: "84572",
                  revealDelay: .3
                }
              }
            )
          }

        },

      }
    });

  }

  function documentReadyFunctions() {
    initSwiper();
    initTextScrambler();
    heroGraphAnimation();
  }

  d.addEventListener('DOMContentLoaded', documentReadyFunctions)


})(window, document);
